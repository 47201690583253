/* override react-tooltip styles */

:root {
  --rt-opacity: 1 !important;
  --rt-color-dark: #6c737d !important;
}

.c-tooltip-z-max {
  z-index: 9999 !important;
}
.c-tooltip-pdf-smiles {
  max-width: 23rem;
  word-break: break-all;
}

.c-tooltip.c-tooltip-filename {
  max-width: 25rem;
  word-break: break-all;
}

.c-tooltip.c-tooltip-base.c-tooltip-toggle {
  white-space: wrap;
  max-width: 22rem;
}

.c-tooltip.c-tooltip-base {
  padding: 0.75rem;
  border-radius: 0.75rem;
  background: #6c737d;
  box-shadow: 0px 0px 8px 0px rgba(45, 55, 69, 0.2);
  backdrop-filter: blur(30px);

  color: #fff;
  font-family: 'Geologica Cursive';
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  z-index: 1;
}

.c-tooltip.c-tooltip-baskets {
  max-width: 200px;
}

.c-tooltip.c-tooltip-password-check {
  background: #f3f5f8;
  box-shadow: 0px 0px 8px 0px rgba(45, 55, 69, 0.16);
  opacity: 1;
}

.c-tooltip.c-tooltip-molviewer {
  max-width: 248px;
}

.c-tooltip-id {
  white-space: wrap;
  text-align: start;
}

.c-tooltip.c-tooltip-molecule-card-note {
  min-width: 2.4rem;
  max-width: 17.5rem;
  word-break: break-all;
}

.c-tooltip-text-align-center {
  text-align: center;
}
.c-tooltip-text-align-left {
  text-align: left;
}

.c-tooltip.c-tooltip-molecule-dialog-note {
  max-width: 80%;
  word-break: break-all;
}

.c-tooltip.c-tooltip-molecule-page-note {
  max-width: 40%;
  word-break: break-all;
}

.c-tooltip.c-tooltip-reactions-score {
  max-width: 26rem;
}

.c-tooltip.c-tooltip-base.c-tooltip-nmrtable {
  z-index: 11;
}

.c-tooltip.c-tooltip-base.c-tooltip-synthcost {
  margin-left: -0.5rem;
}

.c-tooltip.c-tooltip-base.c-tooltip-category-blured {
  text-align: center;
}
.c-tooltip.c-tooltip-base.c-tooltip-category-value {
  max-width: 23.75rem;
  word-break: break-all;
  white-space: wrap;
  text-align: left;
}

.c-tooltip.c-tooltip-base.c-tooltip-category-syn {
  text-align: right;
}

.c-tooltip.c-tooltip-base.c-tooltip-generator-selected {
  max-width: 25rem;
  white-space: pre-line;
  z-index: 16;
  margin-right: 5rem;
  left: 7.925rem !important;
}

.c-tooltip.c-tooltip-reaction-condition {
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(45, 55, 69, 0.04),
    0px -4px 12px 0px rgba(45, 55, 69, 0.04),
    0px 0px 4px 0px rgba(45, 55, 69, 0.02);
  opacity: 1;
}

[class*='react-tooltip__place-bottom-start'] > .c-tooltip-arrow,
[class*='react-tooltip__place-top-start'] > .c-tooltip-arrow {
  left: 1rem !important;
}

[class*='react-tooltip__place-bottom-end'] > .c-tooltip-arrow,
[class*='react-tooltip__place-top-end'] > .c-tooltip-arrow {
  right: 1rem !important;
  left: auto !important;
}

.c-tooltip-arrow {
  background: url('../../../assets/tooltip_arrow.svg') !important;
  height: 0.5rem !important;
  width: 1.5rem !important;
}

.c-tooltip-arrow.c-tooltip-arrow-white {
  background: url('../../../assets/tooltip_arrow_white.svg') !important;
}

[class*='react-tooltip__place-bottom'] > .c-tooltip-arrow,
[class*='react-tooltip__place-bottom-start'] > .c-tooltip-arrow,
[class*='react-tooltip__place-bottom-end'] > .c-tooltip-arrow {
  transform: none !important;
  top: -0.5rem !important;
}

[class*='react-tooltip__place-top'] > .c-tooltip-arrow,
[class*='react-tooltip__place-top-start'] > .c-tooltip-arrow,
[class*='react-tooltip__place-top-end'] > .c-tooltip-arrow {
  transform: rotate(180deg) !important;
  bottom: -0.5rem !important;
}

[class*='react-tooltip__place-left'] > .c-tooltip-arrow,
[class*='react-tooltip__place-left-start'] > .c-tooltip-arrow,
[class*='react-tooltip__place-left-end'] > .c-tooltip-arrow {
  transform: rotate(90deg) !important;
  right: -1rem !important;
}

[class*='react-tooltip__place-right'] > .c-tooltip-arrow,
[class*='react-tooltip__place-right-start'] > .c-tooltip-arrow,
[class*='react-tooltip__place-right-start'] > .c-tooltip-arrow {
  transform: rotate(-90deg) !important;
  left: -1rem !important;
}
