.property-value_blured-true {
  position: relative;
  min-height: 1.5rem;
  min-width: 1.5rem;
  &:before {
    content: '';
    position: absolute;
    min-height: 1.5rem;
    min-width: 1.5rem;
    height: 100%;
    transform: translateY(-50%);
    top: 50%;
    left: 0rem;
    right: 0rem;
    backdrop-filter: blur(2px);
    background-color: #f3f5f8d6;
    cursor: pointer;
    background-image: url('/src/assets/eye-brow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem;
    border-radius: 0.375rem;
    border: 1px solid #e6ebf1;
  }
}
